import {
  BaseGraphSecretV3,
  CellId,
  DataConnectionId,
  GraphNodeV3,
  HexVersionId,
  getCellIdsToSkipForAppRuns,
  getGraphNodeV3Id,
  getProjectGraphV3,
  notEmpty,
  sortGraphNodeV3s,
  typedObjectEntries,
  typedObjectKeys,
} from "@hex/common";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createReducer,
  createSlice,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { isEqual, memoize } from "lodash";

import { getCellsInApp } from "../../components/shared-filter/sharedFilterSelectors.js";
import { getCellReferenceMapV3 } from "../../graph/getCellReferencesV3";
import { GridRowMpFragment } from "../../hex-version-multiplayer/HexVersionMPModel.generated.js";
import { getSelectorsForEntityState } from "../utils/entityAdapterSelectorCreator";

import { CellContentsMP, CellMP } from "./hexVersionMPSlice";

type GraphNodePrunedState = {
  id: CellId;
  pruned: boolean;
};

export type ProjectGraphV3SliceValue = {
  graphNodes: EntityState<GraphNodeV3<CellMP>>;
  projectDataConnections: EntityState<DataConnectionId>;
  prunedCellsState: EntityState<GraphNodePrunedState>;
};

type UpdateProjectDataConnectionsPayload = {
  hexVersionId: HexVersionId;
  projectDataConnections: DataConnectionId[];
};

type UpdateProjectGraphV3Payload = {
  hexVersionId: HexVersionId;
  cells: Record<CellId, CellMP | undefined>;
  cellContents: Record<CellId, CellContentsMP | undefined>;
  secrets: BaseGraphSecretV3[];
  dataConnections: DataConnectionId[];
  gridRows: GridRowMpFragment[];
  unidfQueryMode: boolean;
};

const graphNodeV3Adapter = createEntityAdapter<GraphNodeV3<CellMP>>({
  selectId: getGraphNodeV3Id,
  sortComparer: sortGraphNodeV3s,
});

const projectDataConnectionsAdapter = createEntityAdapter<DataConnectionId>({
  selectId: (id) => id,
  sortComparer: (a, b) => a.localeCompare(b),
});

const graphNodePrunedStateAdapter = createEntityAdapter<GraphNodePrunedState>({
  selectId: (node) => node.id,
  sortComparer: (a, b) => a.id.localeCompare(b.id),
});

export const initialProjectGraphV3ValueState: ProjectGraphV3SliceValue = {
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  graphNodes: graphNodeV3Adapter.getInitialState(),
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  projectDataConnections: projectDataConnectionsAdapter.getInitialState(),
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  prunedCellsState: graphNodePrunedStateAdapter.getInitialState(),
};

const projectGraphV3ValueSlice = createSlice({
  name: "projectGraphV3",
  initialState: initialProjectGraphV3ValueState,
  reducers: {
    updateProjectGraphV3: (
      state,
      action: PayloadAction<UpdateProjectGraphV3Payload>,
    ) => {
      const { cellContents, cells, dataConnections, gridRows, secrets } =
        action.payload;

      const cellMap = getCellReferenceMapV3(cells, cellContents);
      const { graph } = getProjectGraphV3({
        cells: cellMap,
        dataConnections,
        secrets,
      });

      if (cells != null) {
        const cellsValues = Object.values(cells).filter(notEmpty);
        const cellsInApp = getCellsInApp(cellsValues, gridRows);

        const prunedCellIds = getCellIdsToSkipForAppRuns({
          cellReferences: cellMap,
          cellsInApp,
          graphV3: graph,
        });

        const prunedCellState = cellsValues.map((cell) => {
          return { id: cell.id, pruned: prunedCellIds.has(cell.id) };
        });

        graphNodePrunedStateAdapter.setAll(
          state.prunedCellsState,
          prunedCellState,
        );
      }

      const nodeIds = new Set<EntityId>(Object.keys(graph));

      // Remove any nodes that are no longer in the graph
      for (const nodeId of state.graphNodes.ids) {
        if (!nodeIds.has(nodeId)) {
          graphNodeV3Adapter.removeOne(state.graphNodes, nodeId);
        }
      }

      // Update nodes if they have changed, manually checking each field because upsertOne only does shallow compare
      for (const [nodeId, node] of typedObjectEntries(graph)) {
        const oldNode = state.graphNodes.entities[nodeId];
        if (!oldNode || oldNode.type !== node.type) {
          graphNodeV3Adapter.upsertOne(state.graphNodes, node);
        } else {
          const id = getGraphNodeV3Id(node);
          const changes: Partial<GraphNodeV3<CellMP>> = {};
          for (const prop of typedObjectKeys(node)) {
            if (!isEqual(oldNode[prop], node[prop])) {
              changes[prop] = node[prop];
            }
          }
          if (Object.keys(changes).length > 0) {
            graphNodeV3Adapter.updateOne(state.graphNodes, {
              id,
              changes,
            });
          }
        }
      }
    },
    updateProjectDataConnections: (
      state,
      action: PayloadAction<UpdateProjectDataConnectionsPayload>,
    ) => {
      projectDataConnectionsAdapter.setAll(
        state.projectDataConnections,
        action.payload.projectDataConnections,
      );
    },
  },
});

export const projectGraphV3Selectors = {
  getGraphNodeV3Selectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityState(
      graphNodeV3Adapter,
      (state) => state.projectGraphV3[hexVersionId]?.graphNodes,
    );

    return { ...baseSelectors };
  }),
  getPrunedCellsSelectors: memoize((hexVersionId: HexVersionId) => {
    const baseSelectors = getSelectorsForEntityState(
      graphNodePrunedStateAdapter,
      (state) => state.projectGraphV3[hexVersionId]?.prunedCellsState,
    );

    return { ...baseSelectors };
  }),
};

export const { updateProjectGraphV3 } = projectGraphV3ValueSlice.actions;

export const projectGraphV3Actions = {
  ...projectGraphV3ValueSlice.actions,
} as const;

const allActionTypes = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  Object.values(projectGraphV3Actions).map((a) => a.type),
);

type ProjectGraphV3Actions = typeof projectGraphV3ValueSlice.actions;
type ProjectGraphV3ActionPayload = Parameters<
  ProjectGraphV3Actions[keyof ProjectGraphV3Actions]
>[0];

type ProjectGraphV3SliceState = {
  [hexVersionId: string]: ProjectGraphV3SliceValue | undefined;
};

export const projectGraphV3Reducer = createReducer<ProjectGraphV3SliceState>(
  {},
  (builder) =>
    builder.addMatcher(
      (
        action,
      ): action is PayloadAction<
        ProjectGraphV3ActionPayload | UpdateProjectDataConnectionsPayload
      > => allActionTypes.has(action.type),
      (state, action) => {
        state[action.payload.hexVersionId] = castDraft(
          projectGraphV3ValueSlice.reducer(
            state[action.payload.hexVersionId],
            action,
          ),
        );
      },
    ),
);
